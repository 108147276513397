export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    watch: {

    },
    data() {
        return {
            stepHeaderConfig: [],
            nextButtonText: undefined,
            previousButtonText: "Back",
            message: ""
        }
    },
    computed: {
        contact: function () {
            return this.$store.getters.checkoutContact;
        },
        step3SideImgSource(){
            return this.$store.getters.getStep3SideImgSource;
        },
        defaultSidebarImage() {
            const images = this.$store.getters.getDefaultDynamicContent.SidebarImages;
            return images && images.length > 0 ? images.find(image => image.SeqNum === 2) : null;
        },
        dynamicSidebarImage() {
            const images = this.$store.getters.getDynamicContent.SidebarImages;
            return images && images.length > 0 ? images.find(image => image.SeqNum === 2) : null;
        },
    },
    created() {

    },
    mounted(){
        // Set Zenapse Page Change
        let checkExist = setInterval(() => {
            if(typeof Zenapse !== 'undefined'){
                clearInterval(checkExist);
                Zenapse.methods.loadPageEvents();
            }
        },100);

        setTimeout(() => clearInterval(checkExist), 30000);

        this.delay(500).then(() => this.runInvoca());
    },
    methods: {
        
    }
}